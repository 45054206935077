import '@/App.css';
import '@/global.css';
import { Router } from '@/router';
import theme from '@/theme';
import { ThemeProvider } from '@/utils/material';
import type { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router />
      <ToastContainer
        containerId="custom-toast-container"
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
};

export default App;
